<template>
  <div>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      filename="hee hee"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <section class="pdf-item">
         <b-row>
            <b-col
              lg="12"
              cols="12"
              order="1"
              order-lg="1"
            >
                <center>
                <h3 class="mt-5">Daftar Riwayat Hidup</h3>
              </center>
              <table class="table borderless mb-5 mt-5">
                <tr>
                  <td width="30%">Nama</td>
                  <td v-if="profileData.member">
                    {{ profileData.member.nama_lengkap }}
                  </td>
                  <td width="20%" v-if="profileData.member" rowspan="4">
                    <b-img
                      :src="profileData.member.avatar"
                      rounded
                      fluid
                      alt="profile photo"
                    />
                  </td>
                </tr>
                <tr>
                  <td width="30%">Umur</td>
                  <td v-if="profileData.member">
                    {{ profileData.member.age }}
                  </td>
                </tr>
                <tr>
                  <td width="30%">Jenis Kelamin</td>
                  <td v-if="profileData.member && profileData.member.gender">
                    {{ profileData.member.gender.label }}
                  </td>
                </tr>
                <tr>
                  <td width="30%">Email</td>
                  <td v-if="profileData.member">
                    {{ profileData.member.email }}
                  </td>
                </tr>
                <tr>
                  <td width="30%">Ragam Disabilitas</td>
                  <td v-if="profileData.member && profileData.member.disabilitas" colspan="2">
                    {{ profileData.member.disabilitas.label }}
                  </td>
                </tr>
                <tr>
                  <td width="30%">Alamat</td>
                  <td v-if="profileData.member" colspan="2">
                    {{ profileData.member.alamat }}
                  </td>
                </tr>
                <tr>
                  <td width="30%">Provinsi</td>
                  <td v-if="profileData.member && profileData.member.provinsi" colspan="2">
                    {{ profileData.member.provinsi.label }}
                  </td>
                </tr>
                <tr>
                  <td width="30%">Kabupaten / Kota</td>
                  <td v-if="profileData.member && profileData.member.kabkota" colspan="2">
                    {{ profileData.member.kabkota.label }}
                  </td>
                </tr>
              </table>
            </b-col>
         </b-row>
        </section>
        <!-- <div class="html2pdf__page-break"/>
        <section class="pdf-item">
          <h4>
            Title
          </h4>
          <span>
            Value
          </span>
        </section> -->
      </section>
    </vue-html2pdf>
  </div>
</template>
<script>
import VueHtml2pdf from 'vue-html2pdf'
import { BRow, BCol, BImg } from 'bootstrap-vue'
import store from '@/store'
import storeModule from '@/connection-api/user/storeModule'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    VueHtml2pdf,
  },
  data() {
    return {
      profileData: { },
      optionsLocal: {},
      profileFile: null,
      userData: JSON.parse(localStorage.getItem('userData')),
      userId: null,
      /* eslint-disable global-require */
      avatar: require('@/assets/images/avatars/default.png'),
      /* eslint-disable global-require */
    }
  },
  unmounted() {
    const USER_APP_STORE_MODULE_NAME = 'pengguna'
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  },
  async mounted() {
    if (this.$route.params.id) {
      this.userId = this.$route.params.id
    } else {
      this.userId = this.userData.id
    }
    const USER_APP_STORE_MODULE_NAME = 'pengguna'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    store.dispatch('pengguna/fetchData', { id: this.userId })
      .then(response => {
        this.profileData = response.data.data
        this.generateReport()
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.profileData = {}
        }
      })
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
  },
}
</script>
